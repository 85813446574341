html, body {
  overflow: hidden;
}


body {
  position: relative;
  margin: 0;
  padding: 0;
}

.div-scroll {
    overflow-y: auto;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.blink {
    animation: blink 2s steps(5, start) infinite;
    -webkit-animation: blink 1s steps(5, start) infinite;
    }
    @keyframes blink {
      to {
        visibility: hidden;
      }
    }
    @-webkit-keyframes blink {
      to {
        visibility: hidden;
      }
    }
    
    
.blue {
    color: #3860d5
}


.submenu {
    width: 15rem;
    max-width: 50%;
    justify-content: center;
}
.active.submenu {
    font-weight: bold !important;
    border: #b1d5be 1px solid;
}

.selected-row {
        background-color: #fbfbc5 !important;
}

.menu-big {
    font-size: 1.2em !important;
}

.fullscreen {
   position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3000;
    margin: 0 !important;
    
}

.pin-input {
    border: 2px solid #000;
    width: 2em;  
    text-align: center;
    margin: 5px;
    max-width: 100%;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255,255,255,0);
    text-align: center;
    line-height: 2em;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-size: 2em;
    padding: 0.1em 0.1em;
    background: #fff;
    border: 1px solid rgba(34,36,38,.15);
    color: rgba(0,0,0,.87);
    border-radius: 0.28571429rem;
    transition: box-shadow .1s ease,border-color .1s ease;
    box-shadow: none;
    -webkit-text-security: disc !important;
}

.pdf-page {
    width:0;
    margin: auto;
}

.route-segment {
    margin-top: 0!important;
    cursor: pointer;
}

.route-segment:hover {
    border-color: #b7b7b7;
}

.truncate-text {
  white-space: nowrap; /* Текст не переносится */
  overflow: hidden; /* Обрезаем всё за пределами блока */
  text-overflow: ellipsis; /* Добавляем многоточие */
 }